import React, { useState, useEffect } from 'react';

import {Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const PlacesSearch = (props) => {
  const [address, setAddress ] = useState('');

  const onChange = (val) => {
    setAddress(val);
  }
  
  const onSelect = async(val) => {
    setAddress(val);
    const geocode = await geocodeByAddress(val);
    const latlng = await getLatLng(geocode[0]);
    props.geoSort(latlng);
  }

  return (
    <PlacesAutocomplete value={address} onChange={onChange} onSelect={onSelect} searchOptions={{componentRestrictions: {country: ['ch']}}}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
              <Input {...getInputProps({
                placeholder: 'Ort suchen...',
                className: 'location-search-input',
              })} />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })} key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}

    </PlacesAutocomplete>
  )
}

export default PlacesSearch;